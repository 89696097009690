@tailwind base;
@tailwind components;
@tailwind utilities;

.labelForm {

    font-size: 14px;

    color: #000000;

    line-height: 22px;

}